import React from 'react'

import Layout from '../components/layout'

const MkPage = () => (
  <Layout>
    <h1 className="mt-5">Der MK auf einen Blick</h1>

    <h2 className="mt-5">Geschichte</h2>

    <ul>
      <li>1970: Informelle Gründung des so genannten „Marburger Kreises“ innerhalb des „Coburger Convents“ (CC)</li>
      <li>1971: Austritt von zwanzig Turnerschaften aus dem CC</li>
      <li>06.11.1971: Gründung des „Marburger Konvents“, kurz MK auf dem Haus der Turnerschaft Philipinia zu Marburg mit 13 Bünden</li>
      <li>1972: Erstes Dachverbandstreffen in Goslar</li>
      <li>1975: Dauerhafte Etablierung des jährlichen Dachverbandstreffens in der Stadt Hann. Münden</li>
    </ul>
    <p>Drei regelmäßig vom Dachverband ausgerichtete Veranstaltungen im Jahr: Zu Pfingsten, im November und April</p>

    <p>Der „Marburger Konvent studentischer Verbindungen“, kurz MK, bildete sich zunächst unter der Bezeichnung „Marburger Kreis" innerhalb des „Coburger Convents“, kurz CC als lockere Gesprächsrunde von Turnerschaften (und anfangs auch einigen Landsmannschaften), die das Bestreben zusammenführte, das Sportprinzip des Verbandes stärker zu verwirklichen, das Fechten als mögliche Alternative neben andere Sportarten zu stellen und die Entscheidung über die Frage der Pflichtmensur den einzelnen Bünden zu überlassen. Vor allem sollte die Reglementierung der einzelnen Bünde durch die Verbandsorgane verringert werden um so insbesondere einer bindend einheitlichen politischen Ausrichtung durch den Verband entgegenzutreten.</p>

    <p>Als Pfingsten 1971 die große Mehrheit der CC-Bünde jeden Kompromiss in diesen angesprochenen Punkten ablehnte, traten die reformwilligen Turnerschaften im Laufe der nächsten Monate aus dem CC aus. Schließlich schlossen sich 13 von ihnen am 06.11.1971 auf dem Haus der Turnerschaft Philippina zu Marburg zu einem neuen Verband zusammen.</p>

    <p>Der Verband wurde 1972/73 in "Marburger Konvent" umbenannt, da die alte Bezeichnung bereits von einem eingetragenen Verein geführt wurde. Wechselten die jährlichen Tagungsorte zu Beginn noch, so führt der MK sein jährliches Verbandstreffen zu Pfingsten seit 1975 in der Stadt Hann. Münden durch. Als Zeichen der Verbundenheit mit der Stadt führen der MK und seine Präsiden als Vertreter die Farben der Stadt, Rot und Gelb.</p>

    <h2 className="mt-5">Grundsätze und Statuten</h2>
    <p>Das Fundament der Gemeinsamkeiten der Mitgliedsbünde des MKs findet sich in dem 1971 auf dem Haus der Turnerschaft Philipina zu Marburg unterzeichneten Gründungsvertrag, dessen Prinzipien dann in der 1975 aufgelegten Satzung ausformuliert worden sind. Demnach bekennen alle Bünde des Marburger Konvents die</p>

    <ul>
      <li>Lebensbindung an den Bund (Lebensbundgrundsatz),</li>
      <li>Demokratische Ordnung des Bundeslebens (Conventsgrundsatz),</li>
      <li>Förderung der Haltung und Einsatzbereitschaft der Einzelmitglieder im Bund, in der Hochschule, im Beruf und in der Gesellschaft (Persönlichkeitsgrundsatz),</li>
      <li>Führung von Verbindungsfarben als Ausdruck der inneren und äußeren Zusammengehörigkeit (Farbengrundsatz) und</li>
      <li>Pflege von Leibesübungen (Sportgrundsatz).</li>
    </ul>

    <h2 className="mt-5">Organisation</h2>
    <p>Grundlegendes Organ des MKs ist die jährlich zu Pfingsten stattfindende Verbandsversammlung. Auf dieser werden die präsidierenden Bünde der nächsten Jahre festgelegt und die verschiedenen Organe und Ausschüsse des Verbands gewählt.</p>
    <p>Zu diesen zählen der Ständig Tagende Ausschuss („STA“), welcher inhaltliche Schwerpunkte der Dachverbandsarbeit erarbeitet, die Redaktion der Verbandszeitung („MK-Nachrichten“), das Kassenamt sowie für besondere Fälle geschaffene Kommissionen.</p>
  </Layout>
)

export default MkPage 